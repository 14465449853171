






import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';

import TabBar from './TabBar.vue';

@Component
export default class Tab extends Vue {
  @Prop({ required: true })
  label: string;

  @Prop({ default: false })
  active: boolean;

  @Prop({ default: false })
  disabled: boolean;

  isActive: boolean;

  created(): void {
    (this.$parent as TabBar).tabs.push(this);
    this.isActive = this.active;
  }

  beforeDestroy(): void {
    (this.$parent as TabBar).tabs.slice(this.index, 1);
  }

  get index(): number {
    return (this.$parent as TabBar).tabs.indexOf(this);
  }

  @Watch('$parent.activeTab')
  onTabSelect(idx: number): void {
    this.isActive = this.index === idx;
  }
}
