
















import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { CompanyExt, Integration } from '@/models';
import { IntegrationsService } from '@/lib/services/integrations';
import IntegrationConfigModal from '@/components/integration/IntegrationConfigModal.vue';
import { unwrapError } from '@/lib/helpers';

@Component({
  components: {
    IntegrationConfigModal,
  },
})
export default class AccountantIntegrationEdit extends Vue {
  @Prop({ required: true })
  company: CompanyExt;

  service: IntegrationsService = new IntegrationsService(true);
  integration: Integration | null = null;
  loading: boolean = true;
  saving: boolean = false;

  async mounted(): Promise<void> {
    try {
      this.integration = await this.service.get(
        this.company.bookkeeping!,
        this.company.id,
      );
      this.loading = false;
    } catch (e) {
      this.$toaster.error('Error loading settings', unwrapError(e));
    }
  }

  async saveSettings(props: Record<string, unknown>): Promise<void> {
    try {
      this.saving = true;
      await this.service.update(
        this.company.bookkeeping!,
        props,
        this.company.id,
      );
      this.$toaster.success('Settings saved');
    } catch (e) {
      this.$toaster.error('Error saving settings', unwrapError(e));
    } finally {
      this.saving = false;
    }
  }
}
