























































































































































































































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

import SearchInput from '@/components/form/SearchInput.vue';
import { Office, CompanyExt } from '@/models';
import AccountantIntegrationEdit from '@/components/AccountantIntegrationEdit.vue';
import { TabBar, Tab } from '@/components/tabbar';
import { supportedUserLanguages } from '@/plugins/i18n';

@Component({
  components: {
    TabBar,
    Tab,
    AccountantIntegrationEdit,
    SearchInput,
  },
})
export default class CompanyEditModal extends Vue {
  @Prop({ default: () => new CompanyExt(), type: CompanyExt })
  company: CompanyExt;

  @Prop({ default: false })
  editing: boolean;

  @Prop({ default: () => [] })
  offices: Office[];

  @Prop({ required: true })
  handler: (company: CompanyExt) => Promise<number>;

  @Prop({ default: false })
  resetHandler: (company: CompanyExt) => Promise<void>;

  @Prop({ default: false })
  detachHandler: (company: CompanyExt) => Promise<void>;

  languages = supportedUserLanguages;

  activeIndex: number = 0;

  onTabSelect(index: number): void {
    this.activeIndex = index;
  }

  async onSubmit(): Promise<void> {
    await this.handler(this.company);
    this.$emit('saved', this.company);
  }
}
