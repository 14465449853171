























import Vue from 'vue';
import { Component } from 'vue-property-decorator';

import Tab from './Tab.vue';

@Component
export default class TabBar extends Vue {
  tabs: Tab[] = [];
  activeTab: number = 0;
  showMenu: boolean = false;

  mounted(): void {
    this.activeTab = this.getInitialActiveTab();
    this.select(this.activeTab, true);
  }

  isActive(idx: number): boolean {
    return this.activeTab === idx;
  }

  select(idx: number, noEmit: boolean = false): void {
    this.showMenu = !this.showMenu;
    if (!noEmit) {
      this.$emit('select', idx);
    }
    const tab = this.tabs[idx];
    if (!tab.disabled) {
      this.activeTab = idx;
    }
  }

  getInitialActiveTab(): number {
    const idx = this.tabs.findIndex((e) => e.active);
    return idx === -1 ? 0 : idx;
  }
}
